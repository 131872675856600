import { convertSvgToCoordinates, parseSymbols } from '../../../Converter/utils';

export class SymbolSetting {
  public symbol: string = '';

  constructor(symbol: string) {
    this.symbol = symbol;
  }

  public check(): boolean {
    return !!parseSymbols(this.symbol);
  }

  public getIdValue(): string {
    return parseSymbols(this.symbol) ?? '';
  }

  public getPathSummary(): string[] {
    return this.writeSymbol(this.getIdValue());
  }

  private writeSymbol(word: string): string[] {
    const { linePath, polylinePath } = convertSvgToCoordinates(word);
    return [linePath, polylinePath].flat(1);
  }
}
