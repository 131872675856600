import opentype from 'opentype.js';
import { FontInterface } from './type';
import Glyph from './Glyph';

export interface FontOptions {
  fontSize?: number;
  letterSpacing?: number;
  buffer: any;
}

export class Font implements FontInterface {
  private _font: any;

  private _glyph: Glyph;

  private _fontSize = 100;

  private _letterSpacing = 0;

  constructor(options: FontOptions) {
    if (!options.buffer) throw new Error('buffer not found');
    this._font = opentype.parse(options.buffer);
    this._fontSize = options.fontSize ?? 420;
    this._letterSpacing = options.letterSpacing ?? 0;
    this._glyph = new Glyph({ font: this._font });
  }

  get fontBuffer(): any {
    return this._font;
  }

  get letterSpacing(): number {
    return this._letterSpacing;
  }

  public changeFontSize(size: number): void {
    this._fontSize = size;
  }

  public getKerningById(leftId: string, rightId: string): number {
    return this._glyph.getKerningGlyps(leftId, rightId);
  }

  public getAdvancedGlyphWidth(glyphId: string): number {
    const word = document.getElementById(glyphId);
    if (!word) throw new Error(`${word} not fount`);

    return this._glyph.getAdvancedGlyphWidth(word);
  }

  public getScale(): number {
    return this._fontSize / 1000;
  }

  public getTranslateX(glyphId: string, previewGlyphId: string, x: number): number {
    const kerning = this.getKerningById(glyphId, previewGlyphId);
    let summarySpacing = 0;
    summarySpacing += this.getAdvancedGlyphWidth(glyphId) * this.getScale();
    summarySpacing += kerning * this.getScale();
    summarySpacing += this.letterSpacing;
    summarySpacing += x;
    return summarySpacing;
  }

  public countTextWidth(text: string[], brushWidth: number): number {
    let width = 0;

    for (let i = 0; i < text.length; i++) {
      const preview = text[i - 1] ?? '';
      width += this.getTranslateX(text[i], preview, brushWidth);
    }

    return width;
  }
}
