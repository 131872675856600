const parseSymbols = (symbol: string): string | undefined => {
  if (symbol === '&') return 'ampersand';
  if (symbol === '*') return 'asterisk';
  if (symbol === '[') return 'bracketleft';
  if (symbol === ']') return 'bracketright';
  if (symbol === ']') return 'bracketright';
  if (symbol === ':') return 'colon';
  if (symbol === ',') return 'comma';
  if (symbol === '$') return 'dollar';
  if (symbol === '...') return 'ellipsis';
  if (symbol === '-') return 'endash';
  if (symbol === '--') return 'emdash';
  if (symbol === '=') return 'equal';
  if (symbol === '!') return 'exclam';
  if (symbol === '<') return 'guillemotleft';
  if (symbol === '>') return 'guillemotright';
  if (symbol === '(') return 'parenright';
  if (symbol === ')') return 'parenleft';
  if (symbol === '%') return 'percent';
  if (symbol === '.') return 'period';
  if (symbol === '+') return 'plus';
  if (symbol === '?') return 'question';
  if (symbol === "'") return 'quotedbl';
  if (symbol === '"') return 'quotesinglbase';
  return undefined;
};

export default parseSymbols;
