import { fabric } from 'fabric';
import { convertSvgToCoordinates } from '../../../Converter/utils';

export class WordSetting {
  public word: string = '';

  public fontNumber = 0;

  constructor(word: string) {
    this.word = word;
    this.fontNumber = fabric.util.getRandomInt(0, 5);
  }

  public check(): boolean {
    return /^[a-z]$/i.test(this.word);
  }

  public getIdValue(): string {
    return this.fontNumber === 0 || this.word === ' ' ? this.word : `${this.word}.${this.fontNumber}`;
  }

  public getPathSummary(): string[] {
    const editWord = this.getIdValue();
    return this.writeWord(editWord);
  }

  private writeWord(word: string): string[] {
    const { linePath, polylinePath } = convertSvgToCoordinates(word);
    return [linePath, polylinePath].flat(1);
  }
}
