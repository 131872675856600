import { fabric } from 'fabric';
import { convertSvgToCoordinates, parseNumber } from '../../../Converter/utils';

export class NumberSetting {
  public number: string = '';

  public fontNumber = 0;

  constructor(number: string) {
    this.number = number;
    this.fontNumber = fabric.util.getRandomInt(1, 2);
  }

  public check(): boolean {
    return !!Number(this.number);
  }

  // public getIdValue(): string {
  //   return parseNumber(this.number)!;
  // }

  public getIdValue(): string {
    return `${this.number}.${this.fontNumber}`;
  }

  public getPathSummary(): string[] {
    const editNumber = `${this.number}.${this.fontNumber}`;
    return this.writeNumber(editNumber);
  }

  private writeNumber(word: string): string[] {
    const { linePath, polylinePath } = convertSvgToCoordinates(word);
    return [linePath, polylinePath].flat(1);
  }
}
