import * as TWEEN from '@tweenjs/tween.js';
import { interpolateBrushPoints, getEasingFunction } from './utils';
import BrushPoint from '../BrushTools/Brushes/BrushPoint';
import { MainApp } from '../MainApp/MainApp';
import { BrushTypes } from '../../types/types';
import { BrushSettings } from '../BrushTools/types/BrushSettings';
import { FontSettings } from '../CanvasText/type/FontSettings';
import easingJSON from '../../assets/json/easing.json';
import OwnBrushes from '../BrushTools/OwnBrushes';
import { Font } from '../CanvasText/Font';

export interface SvgPainterOptions {
  textCanvas: CanvasRenderingContext2D;
  mainApp: MainApp;
  brushSettings: BrushSettings;
  fontSettings: FontSettings;
}

export class SvgPainter {
  public points: any[] = [];

  public textCanvas: CanvasRenderingContext2D;

  public selectedBrush: BrushTypes;

  private fontScale = 1;

  public mainApp: MainApp;

  public font!: Font;

  public translate: { x: number, y: number } = { x: 0, y: 0 };

  constructor(options: SvgPainterOptions) {
    this.textCanvas = options.textCanvas;
    this.mainApp = options.mainApp;
    const brushesSettings = new OwnBrushes(this.textCanvas, this.mainApp);
    brushesSettings.setSelectedBrush({
      width: options.brushSettings.width,
      opacity: options.brushSettings.opacity,
      color: options.brushSettings.color,
      dispersion: options.brushSettings.dispersion,
      dripLength: options.brushSettings.dripLength,
      dripOpacity: options.brushSettings.dripOpacity,
      dripCurvature: options.brushSettings.dripCurvature,
      endDripSize: options.brushSettings.endDripSize,
      dripSpeed: options.brushSettings.dripSpeed,
      dripAmount: options.brushSettings.dripAmount,
      dripWidth: options.brushSettings.dripWidth,
      dripFlat: options.brushSettings.dripFlat,
    });
    this.selectedBrush = brushesSettings.selectedBrush;
    this.font = new Font({
      fontSize: options.fontSettings.fontSize,
      buffer: this.mainApp.FontBuffer,
      letterSpacing: options.fontSettings.letterSpacing,
    });
  }

  public setPoints(points: any[]): void {
    this.points = points;
  }

  public setScale(): void {
    this.fontScale = this.font.getScale();
  }

  public changeFontsParams(options: FontSettings): void {
    this.font.changeFontSize(options.fontSize);
  }

  public translateGlyph(id: string) {
    this.mainApp.translateX += this.font.getTranslateX(
      id,
      this.mainApp.previewId,
      this.selectedBrush.width,
    );
  }

  public drawGlyph(isAnimate: boolean, id: string, index: number): void {
    const newArr: BrushPoint[] = [];

    this.points.forEach((item) => {
      const point = new BrushPoint({ x: item.x * this.fontScale, y: item.y * this.fontScale });
      newArr.push(point);
    });
    const { interpolatePoints } = interpolateBrushPoints(newArr, this.mainApp.fontSettings.interpolation);

    if (isAnimate) {
      this.animateDraw(interpolatePoints, id.toUpperCase(), index);
    } else {
      interpolatePoints.forEach((point) => {
        this.selectedBrush.setPoint({ x: point.x, y: point.y });
        this.selectedBrush.draw(this.translate.x, this.translate.y);
      });
    }
  }

  private animateDraw(interpolatePoints: BrushPoint[], id: string, index: number): void {
    const keyIndex = String(index);
    const word = easingJSON[id as keyof typeof easingJSON];
    // @ts-ignore
    const easingFunction = word[keyIndex as any];
    const func = this.mainApp.fontSettings.easingAnimation
      ? getEasingFunction(this.mainApp.fontSettings.easingAnimation) : getEasingFunction(easingFunction);
    new TWEEN.Tween({ length: 0 })
      .to({ length: interpolatePoints.length - 1 }, this.mainApp.lineTime + 500)
      .easing(word.easing ? func : undefined)
      .onUpdate(({ length }) => {
        const index = Math.floor(length);
        const point = interpolatePoints[index];
        if (point) {
          this.selectedBrush.setPoint({ x: point.x, y: point.y });
        }
        this.selectedBrush.draw(this.translate.x, this.translate.y + 10);
      })
      .onComplete(() => {
        this.selectedBrush.onEndOfLine(this.translate.x, this.translate.y);
      })
      .start();
  }
}
