import randomMinusPlusRange from '../utils/randomMinusPlusRange';
import Brush from './Brush';
import Drip from './Drip';

class CrayonBrush extends Brush {
  private _strokeCount = 0;

  private _nextDrip = this.getDripAmount();

  public onEndOfLine(x?: number, y?: number): void {
    this._nextDrip = this.getDripAmount();
    this._strokeCount = 0;

    // draw last drip if it possible
    if (this.dripAmount > 0 && this.getStepNum() === 1) {
      this.makeDrip(this.width / 2, x ?? 0, y ?? 0);
    }
  }

  private getDripAmount() {
    return Math.round(250 - this.dripAmount * 2.3);
  }

  private setNextDrip() {
    const dripAmount = this.getDripAmount();
    const floatDistance = Math.round(dripAmount / 4);
    const increaseBy = randomMinusPlusRange(dripAmount - floatDistance, dripAmount + floatDistance);
    this._nextDrip += increaseBy;
  }

  public get strokeCount() {
    return this._strokeCount;
  }

  public increaseStrokeCount() {
    this._strokeCount++;
  }

  public makeDrip(radius: number, x: number, y: number) {
    const myRadius = Math.round(randomMinusPlusRange(radius * 0.1, radius * 0.2) + (this.dripWidth / 2));

    const drip = new Drip({
      ctx: this.ctx,
      radius: myRadius,
      length: this.dripLength,
      color: this.color,
      opacity: this.dripOpacity,
      curvature: this.dripCurvature,
      endDripSize: this.endDripSize,
      speed: this.dripSpeed,
      gradientLength: Math.round(this.width / 6),
      flat: this.dripFlat === 1,
      coords: {
        x: this.point.x,
        y: this.point.y + Math.floor(this.width / 3),
      },
      translate: {
        x,
        y,
      },
    });
  }

  public getStepNum(): number {
    const distance = this.point.distanceFrom(this.latest);
    return Math.floor(distance / (this.width / 5)) + 1;
  }

  public draw(x: number, y: number, dontCountStrokes?: boolean): void {
    if (!dontCountStrokes) {
      this.increaseStrokeCount();
    }

    const radius = this.width / 2;
    const stepNum = this.getStepNum();

    this.ctx.save();
    this.ctx.fillStyle = this.color;
    this.ctx.globalAlpha = this.opacity;
    this.ctx.beginPath();
    this.ctx.translate(x, y);

    const coreRadius = Math.floor(radius / (1 + this.dispersion / 100));
    // радиус должен быть в пределах от 50% до 100% от своего изначального значения
    this.ctx.arc(this.point.x, this.point.y, coreRadius, 0, 360);

    // dispersion

    if (this.dispersion > 0) {
      const circleCount = radius - coreRadius;
      const dotWidth = 1.5;
      let currentRadius = coreRadius;
      const dotsReducer = 1.8;
      const reduceStep = 1.6 / circleCount;
      // кол-во точек плавает от 0.1 до 1.8, в зависимости от близости к ядру
      // на первом круге увеличиваем (* 1.8), на последнем уменьшаем (* 0.2), разница 1.6
      for (let r = 0; r < circleCount; r++) {
        const maxDots = Math.floor(1.5 * Math.PI * currentRadius);
        const reducer = dotsReducer - reduceStep * r;
        const myDotsNum = Math.floor(maxDots * reducer);
        for (let d = 0; d < myDotsNum; d++) {
          const angle = randomMinusPlusRange(0, 360);
          const randX = this.point.x + currentRadius * Math.cos(angle) - 1;
          const randY = this.point.y + currentRadius * Math.sin(angle);
          this.ctx.roundRect(randX, randY, dotWidth, dotWidth, 1);
        }
        currentRadius++;
      }
    }

    this.ctx.fill();
    this.ctx.restore();

    // drips

    if (this.dripAmount > 0 && this._strokeCount === this._nextDrip) {
      this.setNextDrip();

      if (stepNum > 1) {
        return;
      }

      this.makeDrip(radius, x, y);
    }
  }
}

export default CrayonBrush;
