import randomMinusPlusRange from '../utils/randomMinusPlusRange';

interface ICoords {
  x: number;
  y: number;
}

interface DripParams {
  ctx: CanvasRenderingContext2D;
  radius: number;
  length: number;
  opacity: number;
  color: string;
  coords: ICoords;
  translate: ICoords;
  curvature: number;
  endDripSize: number;
  speed: number;
  flat: boolean;
  gradientLength: number;
}

class Drip {
  private _ctx: CanvasRenderingContext2D;

  private _radius: number;

  private _radiusInitial: number;

  private _gradient: number;

  private _gradientStep: number;

  private _length: number;

  private _differenceInlength: number;

  private _opacity: number;

  private _color: string;

  private _curvature: number;

  private _direction: number | null = null;

  private _interval = 20;

  private _endDripSize = 1;

  private _flat = false;

  private _flatWidth: number;

  private _endDripSizeConstant = 0.5;

  private _point: ICoords;

  private _translate: ICoords;

  constructor(params: DripParams) {
    this._ctx = params.ctx;
    this._radius = params.radius;
    this._radiusInitial = params.radius;
    this._flat = params.flat;
    this._flatWidth = params.radius;
    this._gradient = params.gradientLength;
    this._opacity = params.opacity / 100;
    this._gradientStep = this._opacity / this._gradient;
    this._endDripSizeConstant = params.endDripSize / 10;
    this._length = params.length;
    this._differenceInlength = Math.floor(Math.random() * 2); // from 0 to 1
    this._interval = 40 - params.speed;
    this._color = params.color;
    this._point = {
      x: params.coords.x,
      y: params.coords.y,
    };
    this._translate = {
      x: params.translate.x,
      y: params.translate.y,
    };
    this._curvature = params.curvature / 100;
    this.render();
  }

  private update() {
    this._point.y += 1;
    const decrease = Math.floor(Math.random() * (this._length + this._differenceInlength));
    if (decrease === 0) {
      this._radius -= 0.5;
    }

    // curvature

    if (this._direction && this._curvature > 0) {
      const shift = Number(randomMinusPlusRange(0.1, 0.1 + this._curvature, true).toFixed(1));
      if (this._direction === 1) {
        this._point.x += shift;
      } else {
        this._point.x -= shift;
      }
    }

    // curvature direction change

    if (this._radius / this._radiusInitial < 0.9) {
      const chanceToChange = Math.floor(Math.random() * 3);
      if (chanceToChange === 0) {
        this._direction = Math.ceil(Math.random() * 2);
      }
    }
  }

  private getFlatRadius() {
    if (this._radius < 1) {
      this._flatWidth--;
      return this._flatWidth;
    }

    return this._radiusInitial;
  }

  private drawPoint() {
    this._ctx.save();
    let opacity = this._opacity;
    if (this._gradient > 0) {
      opacity -= this._gradientStep * this._gradient;
      this._gradient--;
    }
    this._ctx.globalAlpha = opacity;
    this._ctx.fillStyle = this._color;
    this._ctx.beginPath();
    this._ctx.translate(this._translate.x, this._translate.y);
    const myRadius = this._flat ? this.getFlatRadius() : this._radius;
    if (myRadius > 0) {
      this._ctx.ellipse(this._point.x, this._point.y, myRadius, myRadius / 4, 0, 0, 360);
    }

    // end drip

    if (!this._flat && this._endDripSizeConstant > 0 && this._radius < 1) {
      this._ctx.arc(this._point.x, this._point.y, this._endDripSize, 0, 360);
      // 3 - this._endDripSize = (1.5) !!! BIG DRIPS
      // 3 - this._endDripSize = (3) !!! small drips
      if (this._endDripSize < this._radiusInitial / (3 - this._endDripSizeConstant)) {
        this._endDripSize += 0.5;
      }
    }

    this._ctx.fill();
    this._ctx.restore();
  }

  private render() {
    const draw = () => {
      this.drawPoint();
      this.update();
      if (this._radius > 0) {
        setTimeout(draw, this._interval);
      }
    };

    setTimeout(draw, this._interval);
  }
}

export default Drip;
