export const Positions = [
  { y: 416.8, x: 248 },
  { y: 670.8, x: 248 },
  { y: 924.8, x: 248 },
  { y: 416.8, x: 1240 },
  { y: 1118.4, x: 248 },
  { y: 1118.4, x: 1240 },
  { y: 1820, x: 248 },
  { y: 1820, x: 1240 },
  { y: 2521.6, x: 248 },
  { y: 2521.6, x: 1240 },
  { y: 416.8, x: 248 },
];
