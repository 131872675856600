import { SettingsType } from '../../types/SettingsType';
import { WordSetting } from './WordSetting';
import { SymbolSetting } from './SymbolSetting';
import { NumberSetting } from './NumberSetting';

class Settings {
  private settingValue;

  private wordSettings: WordSetting;

  private symbolSettings: SymbolSetting;

  private numberSetting: NumberSetting;

  constructor(valueSetting: string) {
    this.settingValue = valueSetting;
    this.wordSettings = new WordSetting(valueSetting);
    this.symbolSettings = new SymbolSetting(valueSetting);
    this.numberSetting = new NumberSetting(valueSetting);
  }

  public checkAndReturnSettings(): SettingsType | undefined {
    let setting: SettingsType | undefined;

    if (this.numberSetting.check()) setting = this.numberSetting;
    if (this.wordSettings.check()) setting = this.wordSettings;
    if (this.symbolSettings.check()) setting = this.symbolSettings;

    return setting;
  }
}

export default Settings;
