import { fabric } from 'fabric';
import * as TWEEN from '@tweenjs/tween.js';
import { Positions } from '../../config/Positions';
import Settings from '../ParseTextSettings/Settings';
import { SvgPainter } from '../../../Converter/SvgPainter';
import { convertSvgToCoordinates, parsePath } from '../../../Converter/utils';
import { MainApp } from '../../MainApp';
import { getBboxGrid } from '../../utils/helpers';
import { Font } from '../../../CanvasText/Font';

export interface ParserTextOptions {
  mainApp: MainApp;
  painter: SvgPainter;
}

class ParserText {
  public tweens: TWEEN.Tween<any>[] = [];

  public marginY = 254;

  public marginX: number = 0;

  public lineTime = 1800;

  public previewId = '';

  public painter!: SvgPainter;

  private mainApp: MainApp;

  public currentPhrase: string[] = [];

  private countRows = 0;

  public positionId = 8;

  public summaryWordDelay: number = 0;

  constructor(options: ParserTextOptions) {
    this.mainApp = options.mainApp;
    if (!this.mainApp.ctx) return;
    this.painter = options.painter;
  }

  /*
    обход букв и первод их в svg
  */

  public parseWord(word: string, wordId = 0, phrase: string[] = []): void {
    const isCapacity = this.checkCapacity(word);
    this.setRandomPosition(isCapacity);
    this.translateWord(isCapacity, wordId);

    for (let i = 0; i < word.length; i++) {
      const settings = new Settings(word[i]);
      const currentSetting = settings.checkAndReturnSettings();
      const pathSummary = currentSetting?.getPathSummary();
      const id = currentSetting?.getIdValue();
      if (!id) return;
      const delay = i - 1 < 0 ? 0 : this.countDelayWord(this.currentPhrase[i - 1]);

      if (word.length - 1 === i) this.summaryWordDelay = this.countDelayWord(id);

      this.previewId = this.currentPhrase[i - 1];
      this.currentPhrase.push(id);
      this.chainsLinesInWord(true, id, delay, pathSummary);
    }

    this.startAnimation(wordId, phrase);
    this.currentPhrase = [];
    this.tweens = [];
  }

  private startAnimation(wordId: number, phrase: string[]): void {
    this.tweens.forEach((item, i) => {
      if (!this.tweens[i + 1]) return;
      this.tweens[i].chain(this.tweens[i + 1]);
    });

    this.tweens[0].start();
    this.tweens[this.tweens.length - 1].chain(
      new TWEEN.Tween<any>({ d: 0 }).to({ d: 1 }).onComplete(() => {
        setTimeout(() => {
          if (wordId !== phrase.length - 1) {
            this.mainApp.translateX += 70;
            this.parseWord(phrase[wordId + 1].toLowerCase(), wordId + 1, phrase);
          }
        }, this.summaryWordDelay);
      }),
    );
  }

  public setRandomPosition(isCapacity: boolean): void {
    let randomPosition = fabric.util.getRandomInt(0, Positions.length - 1);
    if (!isCapacity) randomPosition = randomPosition % 2 === 0 ? randomPosition : randomPosition - 1;
    this.positionId = randomPosition;
  }

  public chainsLinesInWord(isAnimate: boolean, id: string, delay: number, path?: string[]) {
    let translateX = 0;
    let translateY = 0;

    const tween = new TWEEN.Tween({})
      .delay(delay + 100)
      .to({ }, 0)
      .onUpdate(() => {
        path?.forEach((item, index) => {
          setTimeout(() => {
            if (!this.mainApp.ctx) return;
            this.painter.setScale();
            if (index === 0) {
              translateX = this.mainApp.translateX + this.marginX;
              translateY = this.mainApp.translateY;
              this.painter.translateGlyph(id);
            }
            this.painter.changeFontsParams(this.mainApp.fontSettings);
            const points = parsePath(item);
            this.painter.setPoints(points);
            this.painter.translate.x = translateX;
            this.painter.translate.y = translateY;
            this.painter?.drawGlyph(isAnimate, id, index);
          }, index * this.lineTime);
        });
      });
    this.tweens.push(tween);
  }

  public countDelayWord(prev: string): number {
    const { linePath, polylinePath } = convertSvgToCoordinates(prev);
    const pathSummary = [linePath, polylinePath].flat(1);

    return this.lineTime * pathSummary.length;
  }

  public checkCapacity(word: string): boolean {
    const { width } = getBboxGrid();

    const accessesWidth = this.mainApp.translateX === 0 ? width : 2232 - this.mainApp.translateX - this.marginX;

    const font = new Font({
      fontSize: this.mainApp.fontSettings.fontSize,
      buffer: this.mainApp.FontBuffer,
      letterSpacing: this.mainApp.fontSettings.letterSpacing,
    });

    const newWord: string[] = [];

    for (let i = 0; i < word.length; i++) {
      const settings = new Settings(word[i]);
      const currentSetting = settings.checkAndReturnSettings();
      const id = currentSetting?.getIdValue();
      if (!id) return false;
      newWord.push(id);
    }

    const widthText = font.countTextWidth(newWord, this.mainApp.brushSettings.width ?? 15);
    return widthText < accessesWidth;
  }

  private translateWord(isCapacity: boolean, wordId: number): void {
    if (wordId === 0) {
      const position = Positions[this.positionId];
      this.marginX = position.x;
      this.mainApp.translateY = position.y;
    } else if (!isCapacity) {
      this.countRows += 1;
      const randomSpacing = fabric.util.getRandomInt(-30, 30);
      let translate = (this.mainApp.translateY + this.marginY) + randomSpacing;
      if (this.positionId === 10 && this.countRows === 2) translate = 2750;
      this.mainApp.ctx?.setTransform(1, 0, 0, 1, 0, 0);
      this.mainApp.translateY = translate;
      this.mainApp.translateX = 0;
      this.marginX = 248;
    }
    if (this.mainApp.translateY > 3160) {
      this.mainApp.ctx?.setTransform(1, 0, 0, 1, 0, 0);
      this.mainApp.translateY = 416.8;
      this.mainApp.translateX = 0;
      this.marginX = 248;
    }
  }

  public destroy(): void {
    this.marginX = 0;
    this.mainApp.translateX = 0;
    this.tweens = [];
    this.mainApp.translateY = 0;
    this.currentPhrase = [];
    this.previewId = '';
  }
}

export default ParserText;
