import {
  FC, useContext, useEffect, useState,
} from 'react';
import { ReactSVG } from 'react-svg';
import { fabric } from 'fabric';
import classNames from './Canvas.module.css';
import font from '../../assets/font.svg';
import font1 from '../../assets/font1.svg';
import font2 from '../../assets/font2.svg';
import font3 from '../../assets/font3.svg';
import font4 from '../../assets/font4.svg';
import font5 from '../../assets/font5.svg';
import numbers from '../../assets/numbers.svg';
import { MainApp } from '../../modules/MainApp/MainApp';
import { ConfigProviderContext } from '../../context/ConfigProvider';

const Canvas: FC = () => {
  const [mainApp, setMainApp] = useState<MainApp | null>(null);
  const { config } = useContext(ConfigProviderContext);

  useEffect(() => {
    if (!config) {
      return;
    }

    setTimeout(() => {
      const randomColor = fabric.util.getRandomInt(0, 8);
      const color = config.config.colorPaint[randomColor];
      const mainApp = new MainApp({
        svgId: 'svg', isDebug: false, config, color,
      });
      setMainApp(mainApp);
    }, 1000);
  }, [config]);

  useEffect(() => {
    if (!config) {
      return;
    }

    window.addEventListener('isLoad', () => {
      const randomColor = fabric.util.getRandomInt(0, 4);
      const color = config.config.colorPaint[randomColor];
      const randomWord = fabric.util.getRandomInt(0, 8);
      const word = config.config.randomWords[randomWord];
      const wordArray = word.split(' ');
      if (mainApp && mainApp.brushSettings) {
        mainApp.brushSettings.color = color;
      }
      mainApp?.parserText.destroy();
      mainApp?.parserText.parseWord(wordArray[0].toLowerCase(), 0, wordArray);
      setInterval(() => {
        const randomColor = fabric.util.getRandomInt(0, 4);
        const color = config.config.colorPaint[randomColor];
        const randomWord = fabric.util.getRandomInt(0, 8);
        const word = config.config.randomWords[randomWord];
        const wordArray = word.split(' ');
        if (mainApp && mainApp.brushSettings) mainApp.brushSettings.color = color;
        mainApp?.parserText.destroy();
        mainApp?.parserText.parseWord(wordArray[0].toLowerCase(), 0, wordArray);
      }, 140000);
    });
  }, [mainApp, config]);

  return (
    <div className={classNames.root}>
      <canvas
        id="canvas"
        width={2480}
        height={3508}
      />
      <div style={{ display: 'none' }}>
        <ReactSVG src={font} />
        <ReactSVG src={font1} />
        <ReactSVG src={font2} />
        <ReactSVG src={font3} />
        <ReactSVG src={font4} />
        <ReactSVG src={font5} />
        <ReactSVG src={numbers} />
      </div>
    </div>
  );
};

export default Canvas;
