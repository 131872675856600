import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { JsonConfig } from '../modules/MainApp/types/SettingsType';

interface IProps {
  children: ReactNode;
}

type ConfigProviderContextType = {
  config: JsonConfig | null;
  setConfig: (config: JsonConfig) => void;
};

export const ConfigProviderContext = createContext<ConfigProviderContextType>({
  config: null,
  setConfig: () => undefined,
});

const ConfigProvider = ({ children }: IProps) => {
  const [config, setConfig] = useState<JsonConfig | null>(null);

  useEffect(() => {
    const getConfig = async (): Promise<JsonConfig> => {
      const result = await axios({
        method: 'get',
        url: process.env.REACT_APP_CONFIG_FILE_PATH,
      });
      return result.data as JsonConfig;
    };

    getConfig()
      .then((res) => {
        if (res) {
          setConfig(res);
        }
      });
  }, []);

  const value = useMemo(() => ({
    config,
    setConfig,
  }), [config, setConfig]);

  return (
    <ConfigProviderContext.Provider
      value={value}
    >
      { children }
    </ConfigProviderContext.Provider>
  );
};

export default ConfigProvider;
